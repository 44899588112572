<section id="content">
  <div class="content-wrap" id="privacy">
    <div class="container clearfix">
      <h1>{{ 'PrivacyPolicy.Title' | translate }}</h1>

      <div>
        <h3>{{ 'PrivacyPolicy.Basic.Title' | translate }}</h3>
        <p>{{ 'PrivacyPolicy.Basic.Text1' | translate }}</p>

        <div>
          <p><strong>{{ 'PrivacyPolicy.Basic.WebContact' | translate }}</strong></p>

          <address class="mb-2">
            LE Software Solutions GmbH<br>
            Bertha-Benz-Str. 5<br>
            DE-10557 Berlin<br>
          </address>
          <div class="mb-4">
            <abbr [title]="'TermsOfUse.CEO' | translate"><strong>{{ 'TermsOfUse.CEO' | translate }}:</strong></abbr>
            Dominik Lahnstein<br>
            <abbr title="Phone Number"><strong>{{ 'TermsOfUse.Phone' | translate }}:</strong></abbr> +49 30 994 0490 10<br>
            <abbr title="Email Address"><strong>{{ 'TermsOfUse.Email' | translate }}:</strong></abbr> info@le-soft.io<br>
            <abbr [title]="'TermsOfUse.HR' | translate"><strong>{{ 'TermsOfUse.HR' | translate }}:</strong></abbr> HRB
            192874 B<br>
            <abbr [title]="'TermsOfUse.UST-ID' | translate"><strong>{{ 'TermsOfUse.UST-ID' | translate }}:</strong></abbr>
            DE316423521<br>
          </div>
          <p>{{ 'PrivacyPolicy.Basic.Text2' | translate }}</p>
          <p>{{ 'PrivacyPolicy.Basic.Text3' | translate }}</p>
        </div>

        <div>
          <h3>{{ 'PrivacyPolicy.AccessData.Title' | translate }}</h3>

          <p>{{ 'PrivacyPolicy.AccessData.Text1' | translate }}</p>

          <ul class="ml-4 mb-2">
            <li>{{ 'PrivacyPolicy.AccessData.List.1' | translate }}</li>
            <li>{{ 'PrivacyPolicy.AccessData.List.2' | translate }}</li>
            <li>{{ 'PrivacyPolicy.AccessData.List.3' | translate }}</li>
            <li>{{ 'PrivacyPolicy.AccessData.List.4' | translate }}</li>
            <li>{{ 'PrivacyPolicy.AccessData.List.5' | translate }}</li>
            <li>{{ 'PrivacyPolicy.AccessData.List.6' | translate }}</li>
            <li>{{ 'PrivacyPolicy.AccessData.List.7' | translate }}</li>
          </ul>

          <p>{{ 'PrivacyPolicy.AccessData.Text2' | translate }}</p>
        </div>

        <div>
          <h3>{{ 'PrivacyPolicy.PersonalData.Title' | translate }}</h3>

          <p>{{ 'PrivacyPolicy.PersonalData.Text1' | translate }}</p>
          <p>{{ 'PrivacyPolicy.PersonalData.Text2' | translate }}</p>
          <p>{{ 'PrivacyPolicy.PersonalData.Text3' | translate }}</p>

          <ul class="ml-4  mb-2">
            <li [innerHTML]="'PrivacyPolicy.PersonalData.List.1' | translate"></li>
            <li [innerHTML]="'PrivacyPolicy.PersonalData.List.2' | translate"></li>
            <li [innerHTML]="'PrivacyPolicy.PersonalData.List.3' | translate"></li>
          </ul>

          <h4>{{ 'PrivacyPolicy.PersonalData.ContactData.Title' | translate }}</h4>
          <p>{{ 'PrivacyPolicy.PersonalData.ContactData.Text' | translate }}</p>
        </div>

        <div>
          <h3>{{ 'PrivacyPolicy.FinalProvisions.Title' | translate }}</h3>

          <h4>{{ 'PrivacyPolicy.FinalProvisions.UserRights.Title' | translate }}</h4>
          <p>{{ 'PrivacyPolicy.FinalProvisions.UserRights.Text' | translate }}</p>

          <h4>{{ 'PrivacyPolicy.FinalProvisions.DeleteData.Title' | translate }}</h4>
          <p>{{ 'PrivacyPolicy.FinalProvisions.DeleteData.Text' | translate }}</p>

          <h4>{{ 'PrivacyPolicy.FinalProvisions.RightToObject.Title' | translate }}</h4>
          <p>{{ 'PrivacyPolicy.FinalProvisions.RightToObject.Text' | translate }}</p>

          <p [innerHTML]="'PrivacyPolicy.FinalProvisions.Text' | translate" class="mt-5"></p>
        </div>
      </div>
    </div>
  </div>
</section>
