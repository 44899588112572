<div class="heading-block fancy-title border-bottom-0 title-bottom-border">
  <h4 [innerHTML]="'Services.Tabs.Projects.Title' | translate"></h4>
</div>

<ul class="tab-ul">
  <li [innerHTML]="'Services.Tabs.Projects.Text.1' | translate"></li>
  <li [innerHTML]="'Services.Tabs.Projects.Text.2' | translate"></li>
  <li [innerHTML]="'Services.Tabs.Projects.Text.3' | translate"></li>
  <li [innerHTML]="'Services.Tabs.Projects.Text.4' | translate"></li>
</ul>
