<!-- Copyrights
			============================================= -->

<div id="copyrights">
  <div class="container">

    <div class="row col-mb-30">

      <div class="col-md-6 text-center text-md-left">
        © 2021 LE Software Solutions GmbH<br>
        <div class="copyright-links"><a pageScroll routerLink="terms" href="#terms">{{ 'Footer.Copyright.TermsOfUse' | translate }}</a> / <a pageScroll routerLink="privacy"  href="#privacy">{{ 'Footer.Copyright.PrivacyPolicy' | translate }}</a></div>
      </div>

      <div class="col-md-6 text-center text-md-right">
        <div class="d-flex justify-content-center justify-content-md-end">

<!--          <a href="#" class="social-icon si-small si-borderless si-twitter">-->
<!--            <i class="icon-twitter"></i>-->
<!--            <i class="icon-twitter"></i>-->
<!--          </a>-->

<!--          <a href="#" class="social-icon si-small si-borderless si-github">-->
<!--            <i class="icon-github"></i>-->
<!--            <i class="icon-github"></i>-->
<!--          </a>-->

<!--          <a href="#" class="social-icon si-small si-borderless si-linkedin">-->
<!--            <i class="icon-linkedin"></i>-->
<!--            <i class="icon-linkedin"></i>-->
<!--          </a>-->

<!--          <a href="#" class="social-icon si-small si-borderless si-xing">-->
<!--            <i class="icon-xing"></i>-->
<!--            <i class="icon-xing"></i>-->
<!--          </a>-->

        </div>

        <div class="clear"></div>

        <i class="icon-mail"></i> info@le-soft.io <span class="middot"></span> <i class="icon-phone"></i> +49 30 994 0490 10
      </div>

    </div>

  </div>
</div>
<!-- #copyrights end -->
