<!-- Page Title
		============================================= -->
<section id="page-title">

  <div class="container clearfix">
    <h1>Page Not Found</h1>
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/home">Home</a></li>
      <li class="breadcrumb-item active" aria-current="page">404</li>
    </ol>
  </div>

</section><!-- #page-title end -->

<!-- Content
============================================= -->
<section id="content">
  <div class="content-wrap">
    <div class="container clearfix">

      <div class="row align-items-center col-mb-80">

        <div class="col-lg-6">
          <div class="error404 center">404</div>
        </div>

        <div class="col-lg-6">

          <div class="heading-block text-center text-lg-left border-bottom-0">
            <h4>Ooopps.! The Page you were looking for, couldn't be found.</h4>
          </div>

          <div class="row col-mb-30">

            <div class="col-6 col-sm-4 widget_links">
              <ul>
                <li><a routerLink="/home">Home</a></li>
              </ul>
            </div>
          </div>

        </div>

      </div>

    </div>
  </div>
</section><!-- #content end -->
