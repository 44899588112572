<!-- Page Title
============================================= -->
<section id="page-title" class="page-title-parallax page-title-dark include-header background">

  <div class="container clearfix">
    <h1>{{ 'Slider.Title' | translate }}</h1>
    <span>{{ 'Slider.Text' | translate }}</span>
  </div>

  <a pageScroll href="#services" data-offset="100" class="one-page-arrow dark"><i class="icon-angle-down infinite animated fadeInDown"></i></a>

</section><!-- #page-title end -->
