<div class="section mt-0 border-top-0 pb-5 pt-5">
  <div class="container clearfix">
    <div class="heading-block center m-0">
      <h3>{{ 'Services.Header.Title' | translate }}</h3>
    </div>
  </div>
</div>

<div class="container clearfix">
  <div class="row align-items-start mb-5">

    <div class="col-lg-5">
      <div class="heading-block">
        <h1>{{ 'Services.LeftPane.Title1' | translate }}<br><span>{{ 'Services.LeftPane.Title2' | translate }}</span></h1>
      </div>
      <p class="lead">{{ 'Services.LeftPane.Text' | translate }}</p>
    </div>

    <div class="col-lg-6 offset-lg-1 align-self-start">


      <div class="tabs tabs-justify tabs-bordered clearfix ui-tabs ui-corner-all ui-widget ui-widget-content"
           id="tab-2">

        <ul class="tab-nav clearfix ui-tabs-nav ui-corner-all ui-helper-reset ui-helper-clearfix ui-widget-header"
            role="tablist">
          <li role="tab" tabindex="0"
              class="ui-tabs-tab ui-corner-top ui-state-default ui-tab ui-tabs-active ui-state-active"
              aria-controls="tabs-1" aria-labelledby="ui-id-1" aria-selected="true" aria-expanded="true"><a
            href="#tabs-1" role="presentation" tabindex="-1" class="ui-tabs-anchor" id="ui-id-1">{{ 'Services.Tabs.Expert.TabName' | translate }}</a></li>
          <li role="tab" tabindex="-1" class="ui-tabs-tab ui-corner-top ui-state-default ui-tab" aria-controls="tabs-2"
              aria-labelledby="ui-id-2" aria-selected="false" aria-expanded="false">
            <a href="#tabs-2"
               role="presentation" tabindex="-1"
               class="ui-tabs-anchor"
               id="ui-id-2">{{ 'Services.Tabs.Teams.TabName' | translate }}</a>
          </li>
          <li class="hidden-phone ui-tabs-tab ui-corner-top ui-state-default ui-tab" role="tab" tabindex="-1"
              aria-controls="tabs-3" aria-labelledby="ui-id-3" aria-selected="false" aria-expanded="false">
            <a href="#tabs-3" role="presentation" tabindex="-1" class="ui-tabs-anchor" id="ui-id-3">{{ 'Services.Tabs.Projects.TabName' | translate }}</a>
          </li>
        </ul>

        <div class="tab-container">

          <div class="tab-content clearfix ui-tabs-panel ui-corner-bottom ui-widget-content" id="tabs-1"
               aria-labelledby="ui-id-1" role="tabpanel" aria-hidden="false">
            <app-expert></app-expert>
          </div>
          <div class="tab-content clearfix ui-tabs-panel ui-corner-bottom ui-widget-content" id="tabs-2"
               aria-labelledby="ui-id-2" role="tabpanel" aria-hidden="true" style="display: none;">
            <app-team></app-team>
          </div>
          <div class="tab-content clearfix ui-tabs-panel ui-corner-bottom ui-widget-content" id="tabs-3"
               aria-labelledby="ui-id-3" role="tabpanel" aria-hidden="true" style="display: none;">
            <app-project></app-project>
          </div>

        </div>

      </div>


    </div>

  </div>
</div>

