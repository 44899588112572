import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  languages: any = {
    en: {lang: 'en', flagImg: './assets/images/flags/en.svg', text: 'English'},
    de: {lang: 'de', flagImg: './assets/images/flags/de.svg', text: 'Deutsch'}
  };

  activeLanguage = this.languages.de;

  constructor(private translate: TranslateService) {
  }

  ngOnInit(): void {
  }

  getLanguageText(lang: any): string {
    return lang.text;
  }

  setLanguage(lang: any): void {
    this.activeLanguage = lang;
    this.translate.use(lang.lang);
  }

}

