<div class="section mt-0 border-top-0 pb-5 pt-5">
  <div class="container clearfix">
    <div class="heading-block center m-0">
      <h3>{{ 'About.Header.Title' | translate }}</h3>
    </div>
  </div>
</div>

<!-- Content
============================================= -->

<div class="container clearfix">

  <div class="row col-mb-50 mb-0">

    <div class="col-lg-4">

      <div class="heading-block fancy-title border-bottom-0 title-bottom-border">
        <h4 [innerHTML]="'About.Content.WhoWeAre.Title' | translate"></h4>
      </div>

      <p>{{ 'About.Content.WhoWeAre.Text' | translate }}</p>

    </div>



    <div class="col-lg-4">

      <div class="heading-block fancy-title border-bottom-0 title-bottom-border">
        <h4 [innerHTML]="'About.Content.OurMission.Title' | translate"></h4>
      </div>

      <p>{{ 'About.Content.OurMission.Text' | translate }}</p>

    </div>

    <div class="col-lg-4">

      <div class="heading-block fancy-title border-bottom-0 title-bottom-border">
        <h4 [innerHTML]="'About.Content.WhyChooseUs.Title' | translate"></h4>
      </div>

      <p>{{ 'About.Content.WhyChooseUs.Text' | translate }}</p>

    </div>


  </div>

</div>

