<section id="content">
  <div class="content-wrap" id="terms">
    <div class="container clearfix">
      <h1 >{{ 'TermsOfUse.Title' | translate }}</h1>

      <div>
        <address>
          <strong>LE Software Solutions GmbH</strong><br>
          Bertha-Benz-Str. 5<br>
          DE-10557 Berlin<br>
        </address>

        <abbr title="Phone Number"><strong>{{ 'TermsOfUse.Phone' | translate }}:</strong></abbr> +49 30 994 0490 10<br>
        <abbr title="Email Address"><strong>{{ 'TermsOfUse.Email' | translate }}:</strong></abbr> info@le-soft.io

        <p class="pt-5">{{ 'TermsOfUse.CEO' | translate }}: Dominik Lahnstein<br>
          {{ 'TermsOfUse.HR' | translate }}: HRB 192874 B<br>
          {{ 'TermsOfUse.UST-ID' | translate }}: DE316423521
        </p>
      </div>
    </div>
  </div>
</section>
