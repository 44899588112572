<!-- Header
============================================= -->
<header id="header" class="full-header" data-sticky-shrink="true" data-sticky-class="not-dark">
  <div id="header-wrap">
    <div class="container">
      <div class="header-row">

        <!-- Logo
        ============================================= -->
        <div id="logo">
          <a routerLink="home" class="standard-logo" data-dark-logo="../assets/images/logo.svg"><img src="../assets/images/logo.svg" alt="LE Software Solutions"/></a>
          <a routerLink="home" class="retina-logo" data-dark-logo="../assets/images/logo.svg"><img src="../assets/images/logo.svg" alt="LE Software Solutions"></a>
        </div><!-- #logo end -->


        <div id="primary-menu-trigger">
          <svg class="svg-trigger" viewBox="0 0 100 100"><path d="m 30,33 h 40 c 3.722839,0 7.5,3.126468 7.5,8.578427 0,5.451959 -2.727029,8.421573 -7.5,8.421573 h -20"></path><path d="m 30,50 h 40"></path><path d="m 70,67 h -40 c 0,0 -7.5,-0.802118 -7.5,-8.365747 0,-7.563629 7.5,-8.634253 7.5,-8.634253 h 20"></path></svg>
        </div>

        <!-- Primary Navigation
        ============================================= -->
        <nav class="primary-menu">

          <ul class="menu-container">
            <li class="menu-item">
              <a pageScroll class="menu-link" routerLink="home" href="#root"><div>{{ 'Header.Menu.Home' | translate }}</div></a>
            </li>
            <li class="menu-item">
              <a pageScroll class="menu-link" routerLink="home" href="#services"><div>{{ 'Header.Menu.OurServices' | translate }}</div></a>
            </li>
            <li class="menu-item">
              <a pageScroll class="menu-link" routerLink="home" href="#about"><div>{{ 'Header.Menu.AboutUs' | translate }}</div></a>
            </li>
            <li class="menu-item">
              <a pageScroll class="menu-link" routerLink="home" href="#footer"><div>{{ 'Header.Menu.Contact' | translate }}</div></a>
            </li>
            <li class="menu-item pl-xl-5">
              <a class="menu-link"><div>{{activeLanguage.text}}</div></a>
              <ul class="sub-menu-container">
                <li *ngFor="let language of languages | keyvalue" class="menu-item">
                  <a class="menu-link" (click)="setLanguage(language.value)"><div>{{getLanguageText(language.value)}}</div></a>
                </li>
              </ul>
            </li>
          </ul>

        </nav><!-- #primary-menu end -->

      </div>
    </div>
  </div>
  <div class="header-wrap-clone"></div>
</header>
<!-- #header end -->
