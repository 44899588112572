import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SlackService {


  constructor(
    private http: HttpClient) {
  }

  sendMessageContactForm(message: any): any {

    console.log(message);
    const webHook = 'https://hooks.slack.com/services/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';

    const options = {
      headers: new HttpHeaders(
        {'Content-Type': 'application/x-www-form-urlencoded'}
      )
    };

    const data = {
      text: '>*Name*: ' + message.name + '\n>*Email*: ' + message.email + '\n```' + message.message + '```'
    };

    return this.http.post<any>(webHook, data, options).subscribe(
      (response) => console.log(response),
      (error) => console.log(error)
    );
  }
}
