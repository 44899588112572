<footer id="footer" class="dark">
  <div class="container">
    <!-- Footer Widgets
    ============================================= -->
    <div class="footer-widgets-wrap">

      <div class="row col-mb-50">
        <div class="col-md-6 col-lg-4">

          <div class="widget clearfix">

            <img src="assets/images/logo.svg" alt="Image" class="footer-logo">

            <div
              style="background: url('assets/images/demo/world-map.png') no-repeat center center; background-size: 100%;">
              <address>
                <strong>{{ 'Footer.Headquarters' | translate }}:</strong><br>
                Bertha-Benz-Str. 5<br>
                DE-10557 Berlin<br>
              </address>
              <abbr title="Phone Number"><strong>{{ 'Footer.Phone' | translate }}:</strong></abbr> +49 30 994 0490 10<br>
              <abbr title="Email Address"><strong>{{ 'Footer.Email' | translate }}:</strong></abbr> info@le-soft.io
            </div>

          </div>

        </div>

        <div class="col-md-6 col-lg-6 offset-lg-2">

          <div class="widget quick-contact-widget form-widget clearfix">

            <h4>{{ 'Footer.Chat.Title' | translate }}</h4>

            <div class="form-result"></div>

            <form [formGroup]="form" (ngSubmit)="submitForm()" id="quick-contact-form" name="quick-contact-form" class="quick-contact-form mb-0">
              <div class="input-group mx-auto">
                <div class="input-group-prepend">
                  <div class="input-group-text"><i class="icon-user"></i></div>
                </div>
                <input type="text" class="required form-control" id="quick-contact-form-name"
                       name="quick-contact-form-name" value="" [placeholder]="'Footer.Chat.FullName' | translate" formControlName="name"/>
              </div>
              <div class="input-group mx-auto">
                <div class="input-group-prepend">
                  <div class="input-group-text"><i class="icon-email"></i></div>
                </div>
                <input type="text" class="required form-control email" id="quick-contact-form-email"
                       name="quick-contact-form-email" value="" [placeholder]="'Footer.Chat.Email' | translate" formControlName="email"/>
              </div>
              <textarea class="required form-control input-block-level short-textarea" id="quick-contact-form-message"
                        name="quick-contact-form-message" rows="4" cols="30" [placeholder]="'Footer.Chat.Message' | translate" formControlName="message"></textarea>
              <button type="submit" id="quick-contact-form-submit" name="quick-contact-form-submit"
                      class="btn btn-danger m-0" value="submit">{{ 'Footer.Chat.Send' | translate }}
              </button>

            </form>

          </div>

        </div>
      </div>

    </div><!-- .footer-widgets-wrap end -->
  </div>
  <app-copyright></app-copyright>


</footer><!-- #footer end -->
