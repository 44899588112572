import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {SlackService} from '../core/slack.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  form: FormGroup;

  constructor(public fb: FormBuilder, public slackService: SlackService) {
    this.form = this.fb.group({
      name: '',
      email: '',
      message: ''
    });
  }

  ngOnInit(): void {
  }

  submitForm(): void {
    this.slackService.sendMessageContactForm(this.form.value);
  }

}
